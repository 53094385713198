import type { HandleClientError } from '@sveltejs/kit';
import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

Sentry.init({
	dsn: env.PUBLIC_SENTRY_SVELTE_DSN,
	initialScope: {
		tags: { type: 'client' }
	},
	tracesSampleRate: 1.0
	// integrations: [Sentry.replayIntegration()],
	// replaysSessionSampleRate: 0.1,
	// replaysOnErrorSampleRate: 1.0
});

export const handleError: HandleClientError = Sentry.handleErrorWithSentry(({ error }) => {
	console.error(error);

	const errorId = crypto.randomUUID();
	const message = 'Whoops! Something went wrong on our site, we are working on it.';
	if (error instanceof Error) {
		return {
			message: error.message,
			errorId
		};
	}

	return { message, errorId };
});
